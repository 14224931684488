import { Box, Container } from '@material-ui/core';
import { Typography } from '@/components/Typography';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { NextPage } from 'next';

import { IPage } from '@/models/IPage.interface';
import { css } from '@emotion/react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 120,
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(5),

  },
}));

interface PageProps {
  page: IPage;
}

const Page: NextPage<PageProps> = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Box py={8} pb="22rem" pt="12rem">
          <Typography variant="displayLarge" className={classes.title} fontWeight='600' fontStyle='italic' customColor='#ebebeb' align='center' styles={css`
            margin-bottom: 0;
          `}>
            404
          </Typography><br />
          <Typography variant="titleLarge" fontWeight='300' align='center' customColor='#ebebeb'>Sidan kunde inte hittas</Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Page;
